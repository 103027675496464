<template>
  <v-app>
    <v-app-bar app>
      <div class="d-flex justify-space-between align-center" style="width:100%">
        <div class="d-flex align-center">
          <router-link class="d-flex align-center" to="/">
            <v-img width="38" contain src="@/assets/images/logo.png"></v-img>
            <div class="text-h6 text-sm-h5 font-weight-medium ml-2">SuperCells</div>
          </router-link>
        </div>

        <div class="d-none d-sm-flex justify-start">
          <v-menu open-on-hover min-width="120" offset-y auto transition="scale-transition" close-delay="200">
            <template v-slot:activator="{ on, attrs }">
              <div class="link px-5" v-bind="attrs" v-on="on">{{ $t('Appbar.About') }}</div>
            </template>
            <v-list class="nav-menu-list pa-0">
              <v-list-item to="/about/us" class="text-body-2">{{ $t('Appbar.AboutUs') }}</v-list-item>
              <v-list-item to="/about/team" class="text-body-2">{{ $t('Appbar.Team') }}</v-list-item>
              <v-list-item to="/about/research" class="text-body-2">{{ $t('Appbar.Results') }}</v-list-item>
              <v-list-item to="/about/activity" class="text-body-2">{{ $t('Appbar.Dynamic') }}</v-list-item>
            </v-list>
          </v-menu>
          <router-link to="/road" class="d-block px-5">{{ $t('Appbar.Road') }}</router-link>
          <a href="https://supercells.pro" target="_blank" class="d-block px-5">{{ $t('Appbar.Foundation') }}</a>

          <v-menu open-on-hover min-width="120" offset-y auto transition="scale-transition" close-delay="200">
            <template v-slot:activator="{ on, attrs }">
              <div class="link px-5" v-bind="attrs" v-on="on">{{ $t('Appbar.Explore') }}</div>
            </template>
            <v-list class="nav-menu-list pa-0">
              <v-list-item to="/explore/market" class="text-body-2">{{ $t('Appbar.Market') }}</v-list-item>
              <v-list-item to="/explore/news" class="text-body-2">{{ $t('Appbar.News') }}</v-list-item>
            </v-list>
          </v-menu>

          <a v-if="newsil8n == 'zh'" href="https://docs.supercells.vip/cn" target="_blank" class="d-block px-5">DOCS</a>
          <a v-if="newsil8n == 'en'" href="https://docs.supercells.vip/en" target="_blank" class="d-block px-5">DOCS</a>
          <a v-if="newsil8n == 'ja'" href="https://docs.supercells.vip/jp" target="_blank" class="d-block px-5">DOCS</a>
          <!-- <router-link to="/" class="d-block px-5">DOCS</router-link> -->

          <v-menu open-on-hover min-width="120" offset-y auto transition="scale-transition" close-delay="200">
            <template v-slot:activator="{ on, attrs }">
              <div class="d-flex justify-start align-center px-5" v-bind="attrs" v-on="on" style="cursor: pointer">
                <img width="22" src="@/assets/images/en.svg" v-if="$i18n.locale == 'en'" />
                <img width="22" src="@/assets/images/ja.svg" v-else-if="$i18n.locale == 'ja'" />
                <img width="22" src="@/assets/images/ko.png" v-else-if="$i18n.locale == 'ko'" />
                <img width="22" src="@/assets/images/zh.svg" v-else />
                <!-- <div class="ml-1">{{ $t($i18n.locale) }}</div> -->
              </div>
            </template>
            <v-list class="nav-menu-list pa-0">
              <v-list-item @click="selectLanguage('zh')">
                <div class="d-flex justify-start align-center">
                  <img width="22" src="@/assets/images/zh.svg" />
                  <div class="ml-2 text-body-2">繁體中文</div>
                </div>
              </v-list-item>
              <v-list-item @click="selectLanguage('en')">
                <div class="d-flex justify-start align-center">
                  <img width="22" src="@/assets/images/en.svg" />
                  <div class="ml-2 text-body-2">English</div>
                </div>
              </v-list-item>
              <v-list-item @click="selectLanguage('ja')">
                <div class="d-flex justify-start align-center">
                  <img width="22" src="@/assets/images/ja.svg" />
                  <div class="ml-2 text-body-2">日本語</div>
                </div>
              </v-list-item>
              <v-list-item @click="selectLanguage('ko')">
                <div class="d-flex justify-start align-center">
                  <img width="22" src="@/assets/images/ko.png" />
                  <div class="ml-2 text-body-2">한국어</div>
                </div>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>

        <div class="d-block d-sm-none">
          <div class="d-flex justify-start align-center">
            <v-menu min-width="120" offset-y auto transition="scale-transition" close-delay="200">
              <template v-slot:activator="{ on, attrs }">
                <div class="d-flex justify-start align-center" v-bind="attrs" v-on="on"
                  style="cursor: pointer;height:62px;">
                  <img width="22" src="@/assets/images/en.svg" v-if="$i18n.locale == 'en'" />
                  <img width="22" src="@/assets/images/ja.svg" v-else-if="$i18n.locale == 'ja'" />
                  <img width="22" src="@/assets/images/zh.svg" v-else />
                  <!-- <div class="ml-1">{{ $t($i18n.locale) }}</div> -->
                </div>
              </template>
              <v-list class="pa-0">
                <v-list-item @click="selectLanguage('zh')">
                  <div class="d-flex justify-start align-center">
                    <img width="22" src="@/assets/images/zh.svg" />
                    <div class="ml-2 text-body-2">繁體中文</div>
                  </div>
                </v-list-item>
                <v-list-item @click="selectLanguage('en')">
                  <div class="d-flex justify-start align-center">
                    <img width="22" src="@/assets/images/en.svg" />
                    <div class="ml-2 text-body-2">English</div>
                  </div>
                </v-list-item>
                <v-list-item @click="selectLanguage('ja')">
                  <div class="d-flex justify-start align-center">
                    <img width="22" src="@/assets/images/ja.svg" />
                    <div class="ml-2 text-body-2">日本語</div>
                  </div>
                </v-list-item>
                <v-list-item @click="selectLanguage('ko')">
                  <div class="d-flex justify-start align-center">
                    <img width="22" src="@/assets/images/ko.png" />
                    <div class="ml-2 text-body-2">한국어</div>
                  </div>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn icon @click="navigationDrawer = !navigationDrawer" class="ml-2">
              <v-icon color="#AC8250">mdi-menu</v-icon>
            </v-btn>
          </div>

          <v-navigation-drawer hide-overlay temporary fixed right width="70%" height="100vh" v-model="navigationDrawer"
            class="nav-drawer">
            <div class="px-5 py-10">
              <div>
                <div>
                  <div>{{ $t('Appbar.About') }}</div>
                  <div class="pl-5">
                    <router-link to="/about/us" class="d-flex justify-space-between align-center mt-1">
                      <div class="text-body-2">{{ $t('Appbar.AboutUs') }}</div>
                      <v-icon>mdi-chevron-right</v-icon>
                    </router-link>
                    <router-link to="/about/team" class="d-flex justify-space-between align-center mt-1">
                      <div class="text-body-2">{{ $t('Appbar.Team') }}</div>
                      <v-icon>mdi-chevron-right</v-icon>
                    </router-link>
                    <router-link to="/about/research" class="d-flex justify-space-between align-center mt-1">
                      <div class="text-body-2">{{ $t('Appbar.Results') }}</div>
                      <v-icon>mdi-chevron-right</v-icon>
                    </router-link>
                    <router-link to="/about/activity" class="d-flex justify-space-between align-center mt-1">
                      <div class="text-body-2">{{ $t('Appbar.Dynamic') }}</div>
                      <v-icon>mdi-chevron-right</v-icon>
                    </router-link>
                  </div>
                </div>

                <router-link to="/road" class="d-flex justify-space-between align-center mt-4">
                  <div class="text-body-1">{{ $t('Appbar.Road') }}</div>
                  <v-icon>mdi-chevron-right</v-icon>
                </router-link>
                <a href="https://supercells.pro" target="_blank" class="d-flex justify-space-between align-center mt-4">
                  <div class="text-body-1">{{ $t('Appbar.Foundation') }}</div>
                  <v-icon>mdi-chevron-right</v-icon>
                </a>

                <div class="mt-4">
                  <div class="text-body-1">{{ $t('Appbar.Explore') }}</div>
                  <div class="pl-5">
                    <router-link to="/explore/market" class="d-flex justify-space-between align-center mt-1">
                      <div class="text-body-2">{{ $t('Appbar.Market') }}</div>
                      <div>
                        <v-icon>mdi-chevron-right</v-icon>
                      </div>
                    </router-link>
                    <router-link to="/explore/news" class="d-flex justify-space-between align-center mt-1">
                      <div class="text-body-2">{{ $t('Appbar.News') }}</div>
                      <div>
                        <v-icon>mdi-chevron-right</v-icon>
                      </div>
                    </router-link>
                  </div>
                </div>
                <a v-if="newsil8n == 'zh'" href="https://docs.supercells.vip/cn" target="_blank"
                  class="d-flex justify-space-between align-center mt-4">
                  <div>DOCS</div><v-icon>mdi-chevron-right</v-icon>
                </a>
                <a v-if="newsil8n == 'en'" href="https://docs.supercells.vip/en" target="_blank"
                  class="d-flex justify-space-between align-center mt-4">
                  <div>DOCS</div><v-icon>mdi-chevron-right</v-icon>
                </a>
                <a v-if="newsil8n == 'ja'" href="https://docs.supercells.vip/jp" target="_blank"
                  class="d-flex justify-space-between align-center mt-4">
                  <div>DOCS</div><v-icon>mdi-chevron-right</v-icon>
                </a>
                <!-- <router-link to="/" class="d-flex justify-space-between align-center mt-4">
                  <div class="text-body-1">DOCS</div>
                  <v-icon>mdi-chevron-right</v-icon>
                </router-link> -->

              </div>
            </div>
          </v-navigation-drawer>

        </div>
      </div>

    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',
  components: {

  },

  data: () => ({
    navigationDrawer: false,
    newsil8n: null
  }),
  methods: {
    selectLanguage(locale) {
      this.$store.commit('setLanguage', locale)
    }
  },
  mounted() {
    this.newsil8n = this.$i18n.locale
  },
  watch: {
    '$i18n.locale'(newil8n) {
      this.newsil8n = newil8n;
    }
  },
};
</script>

<style lang="scss">
.v-app-bar {
  color: #AC8250 !important;

  a,
  .link {
    height: 64px;
    line-height: 64px;
    color: #AC8250 !important;
    cursor: pointer;
  }
}

.nav-menu-list {
  border-top: 4px solid #AC8250 !important;
  border-color: #AC8250;

  a {
    color: #1c1c1c !important;
  }

  .link-item:hover {
    background: rgba(0, 0, 0, 0.05);
  }
}

.v-navigation-drawer__content,
.v-navigation-drawer__content a {
  color: #221816 !important;
}


@media screen and (max-width: 960px) {
  .v-app-bar {

    .v-navigation-drawer__content a {
      height: 32px;
      line-height: 32px;
    }
  }
}
</style>
