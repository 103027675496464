import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';

import i18n from './plugins/i18n';

import './assets/css/reset.scss';
import './assets/css/common.scss';

import store from './store';

Vue.config.productionTip = false

new Vue({
  router,
  i18n,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

