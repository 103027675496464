import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('@/views/Home.vue'),
    meta: {
      layout: 'content',
      title: ''
    }
  },
  {
    path: '/about/us',
    component: () => import('@/views/about/About.vue'),
    meta: {
      layout: 'content',
      title: ''
    }
  },
  {
    path: '/about/team',
    component: () => import('@/views/about/Team.vue'),
    meta: {
      layout: 'content',
      title: ''
    }
  },
  {
    path: '/about/research',
    component: () => import('@/views/about/Research.vue'),
    meta: {
      layout: 'content',
      title: ''
    }
  },
  {
    path: '/about/activity',
    component: () => import('@/views/about/Activity.vue'),
    meta: {
      layout: 'content',
      title: ''
    }
  },
  {
    path: '/about/activity',
    component: () => import('@/views/about/Activity.vue'),
    meta: {
      layout: 'content',
      title: ''
    }
  },
  {
    path: '/about/activity',
    component: () => import('@/views/about/Activity.vue'),
    meta: {
      layout: 'content',
      title: ''
    }
  },
  {
    path: '/road',
    component: () => import('@/views/Road.vue'),
    meta: {
      layout: 'content',
      title: ''
    }
  },
  {
    path: '/explore/market',
    component: () => import('@/views/explore/Market.vue'),
    meta: {
      layout: 'content',
      title: ''
    }
  },
  {
    path: '/explore/news',
    component: () => import('@/views/explore/News.vue'),
    meta: {
      layout: 'content',
      title: ''
    }
  },
  {
    path: '/package/packageA',
    component: () => import('@/views/package/PackageA.vue'),
    meta: {
      layout: 'content',
      title: ''
    }
  },
  {
    path: '/package/packageB',
    component: () => import('@/views/package/PackageB.vue'),
    meta: {
      layout: 'content',
      title: ''
    }
  },
  {
    path: '/package/packageC',
    component: () => import('@/views/package/PackageC.vue'),
    meta: {
      layout: 'content',
      title: ''
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to) {
    return { x: 0, y: to.meta.scrollTop ?? 0 };
  },
});

const DEFAULT_TITLE = 'SuperCells';
router.beforeEach(async (to, from, next) => {
  document.title = DEFAULT_TITLE + (to.meta.title || '');
  from.meta.scrollTop = document.querySelector("html").scrollTop || 0
  next()
  // if (to.meta.requireAuth) {
  //   if (authAccount) {
  //     next();
  //   } else {
  //       next({
  //         path: '/auth',
  //         query: { redirect: to.fullPath },
  //       });
  //   }
  // } else {
  //   next()
  // }
});

export default router
